<template>
  <v-app>
    <v-content class="fullBg">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Admin dashboard login</v-toolbar-title>
                <v-spacer />
                {{apiUrl}}
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" @keyup.native.enter="login">
                  <v-text-field
                    id="email"
                    v-model="form.email"
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    :rules="rules.email"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="password"
                    v-model="form.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    :type="showPass ? 'text' : 'password'"
                    counter
                    :rules="rules.password"
                    :disabled="sending"
                    @click:append="showPass = !showPass"
                    @input="serverErrors = null"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="login">Login</v-btn>
              </v-card-actions>
              <v-progress-linear
                v-if="sending"
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <v-alert v-if="errorMessage" tile class="mt-5" type="error">{{errorMessage}}</v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>

export default {
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
      rules: {
        password: [
          v => !!v || 'Required.',
          v => v ? v.length >= 8 || 'Min 8 characters' : 'Required.',
          () => this.serverErrors && this.serverErrors.password ? this.serverErrors.password[0] : true,
        ],
        email: [
          v => !!v || 'Required.',
          v => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || 'Invalid e-mail.'
          },
          () => this.serverErrors && this.serverErrors.email ? this.serverErrors.email[0] : true,
        ],
      },
      showPass: false,
      errorMessage: null,
      serverErrors: null,
      sending: false,
      valid: false,
    }
  },
  computed: {
    headerStyle () {
      return {
        backgroundImage: `url(${this.header})`,
      }
    },
  },
  methods: {
    login () {
      this.errorMessage = null
      this.serverErrors = null
      if (this.$refs.form.validate()) {
        this.apiLogin()
          .then(() => {
            this.$router.push(this.$route.query.from || '/')
          })
          .catch(error => {
            this.errorMessage = error.message
            this.serverErrors = error.errors
            this.$refs.form.validate()
          })
      }
    },
  },
}
</script>

<style scoped>
.fullBg {
  background-image: url("~@/assets/login.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
